<script>
async function base64ToFile(src, filename) {
	let arr = src.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = window.atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}

	return new File([u8arr], filename, { type: mime })
}

export default {
	lang: 'books',
	data() {
		return {
			cartLoading: false,
			submitLoading: false,
			coverSrc: null,
			customization: {
				data: {},
			},
		}
	},
	computed: {
		productView() {
			return this.$parent.$parent
		},
		info() {
			return this.productView.product.info || {}
		},
		customizationKey() {
			return this.info?.customizationKey
		},
		config() {
			return this.info?.customizationData
		},
		showPortals() {
			return this.customizationKey == 'book_cover'
		},
	},
	methods: {
		async addToCart() {
			let files = {
				img: this.coverSrc && (await base64ToFile(this.coverSrc, `${this.customization.data.name}.png`)),
			}

			await this.$shop.addToCart({
				productId: this.productView.selectedVariant.productId,
				variantId: this.productView.selectedVariant.id,
				qty: 1,
				customization: this.customization,
				files,
				loading: (v) => (this.cartLoading = v),
				onValidation: ({ validation }) => (this.productView.validation = validation),
			})
		},
	},
	created() {
		this.$assign(this.customization, {
			data: {},
			key: this.customizationKey,
		})
	},
}
</script>

<template>
	<div style="display: none" v-if="showPortals">
		<portal to="Product.actions">
			<Button
				@click="$vuetify.goTo('.scroll-point', { offset: 60 })"
				color="secondary"
				class="font-weight-black"
				rounded
				x-large
				block
			>
				{{ '¡Personalízalo ahora!' | lang }}
			</Button>
		</portal>
		<portal to="Product.info" :order="-1">
			<div class="secondary customization-form scroll-point rounded-t-xl">
				<div class="text-center pt-8 d-flex flex-column align-center">
					<div class="font-4 mb-2 font-weight-bold" style="max-width: 95%">
						{{ $lang('¡Ya casi tenés tu libro personalizado!') }}
					</div>
					<div
						class="font-4 title-font white--text primary px-4 py-2 rounded"
						style="width: 800px; max-width: 95%; line-height: 35px"
					>
						{{ $lang('Hacelo único con una dedicatoria y tu foto') }}
					</div>
				</div>
				<Container
					class="d-flex flex-column align-start flex-md-nowrap flex-wrap pt-8 pb-16"
					width="1024px"
					max-width="100%"
				>
					<div class="px-md-4 w100 mb-4">
						<BookCoverCustomizationForm
							:validation="productView.validation"
							:customization-reference="customization"
							:loading="cartLoading"
							@change:file="coverSrc = $event"
							:config="config"
						/>
					</div>
					<div class="w100 preview">
						<BookCoverPreview
							:config="config"
							:name="customization.data.name"
							:inscription="customization.data.inscription"
							:cover-src="coverSrc"
						/>
					</div>
					<div class="w100 d-flex justify-center pt-16">
						<Button
							color="primary"
							class="px-8"
							@click="addToCart"
							:loading="cartLoading"
							:disabled="cartLoading"
							x-large
							rounded
						>
							<v-icon left>mdi-cart-plus</v-icon> Agregar al carrito
						</Button>
					</div>
				</Container>
			</div>
		</portal>
	</div>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.step-number {
	width: 35px;
	height: 35px;
	line-height: 35px;
	margin-right: 16px;
	text-align: center;
	border-radius: 100%;
}

.customization-form ::v-deep .medium-btn {
	min-width: 90px !important;
	@media #{map-get($display-breakpoints, 'md-only')} {
		min-width: 75px !important;
	}
}
.customization-form ::v-deep .customization-tf .v-input__slot {
	background-color: white;
}
.customization-form ::v-deep .customization-tf .v-input__control {
	position: relative;
}
.customization-form ::v-deep .customization-tf .v-text-field__details {
	position: absolute;
	top: 105%;
	left: -12px;
}
.customization-form ::v-deep .customization-tf .v-messages,
.customization-form ::v-deep .custom-error {
	color: white !important;
	background: var(--v-error-base) !important;
	padding: 4px 8px;
	border-radius: 4px;
}
.customization-form ::v-deep .v-btn.nowrap .v-btn__content {
	white-space: nowrap;
}
</style>
