<script>
export default {
	computed: {
		productView() {
			return this.$parent.$parent
		},
		product() {
			return this.productView.product
		},
		info() {
			return this.product.info || {}
		},
		showPortal() {
			return this.info?.templateKey == 'adult-book-template'
		},
		data() {
			return this.info.templateData
		},
		xs() {
			return this.$vuetify.breakpoint.xs
		},
	},
	mounted() {
		console.log(this.data, 'OTRO TITULO DE PRUEBA')
	},
}
</script>

<template>
	<div style="display: none" v-if="showPortal">
		<!-- <portal to="Product.sku">
			<div class="d-flex">
				<v-icon small color="orange" v-for="i of 5" :key="i">mdi-star</v-icon>
			</div>
		</portal> -->
		<portal to="Product.below-name" v-if="data.description || info.description">
			<div class="pr-16 pb-6 preline white--text">
				{{ data.description || info.description }}
			</div>
		</portal>
		<portal to="Product.info">
			<div @click="$vuetify.goTo('.scroll-point', { offset: 60 })">
				<Media eager :src="xs ? data.topImgMobile : data.topImg" width="100%" />
				<Media eager :src="xs ? data.largeImgMobile : data.largeImg" width="100%" />
			</div>

			<div v-if="!xs" class="secondary">
				<Container class="d-flex justify-space-between align-center py-12">
					<Media
						:src="data.imageWithText"
						contain
						width="50%"
						class="py-15"
						:class="{ 'd-flex align-end': xs }"
					>
					</Media>
					<v-row class="h100 flex-grow-1">
						<v-col cols="12" v-for="item of data.imageWithTextItems" :key="item.id">
							<div class="pl-8">
								<div class="font-weight-bold" style="color: #3a4d9b">
									{{ item.title }}
								</div>
								<div style="max-width: 500px" class="">
									{{ item.text }}
								</div>
							</div>
						</v-col>
					</v-row>
				</Container>
			</div>
			<div v-else class="secondary pb-8">
				<Media :src="data.imageWithText" width="100%" class="mb-4"></Media>
				<div v-for="item of data.imageWithTextItems" :key="item.id" class="pa-8">
					<div class="font-weight-bold text-center primary--text">
						{{ item.title }}
					</div>
					<div class="text-center px-4">
						{{ item.text }}
					</div>
				</div>
			</div>
			<Container fluid class="pa-0">
				<v-row no-gutters>
					<v-col
						cols="6"
						md="4"
						v-for="item of data.galleryImages"
						:key="item.id"
						style="overflow: hidden"
					>
						<Media
							:src="item.image"
							@click="$vuetify.goTo('.scroll-point', { offset: 60 })"
							class="img-hover"
						/>
					</v-col>
				</v-row>
			</Container>
			<Container v-if="data.imgTextItems" :fluid="{ xs: true, sm: false }" class="mt-10">
				<div
					v-for="(item, i) of data.imgTextItems"
					:key="i"
					class="d-flex align-center flex-column flex-sm-row pb-8"
				>
					<Media
						:src="item.image"
						:width="xs ? '100%' : '50%'"
						contain
						v-if="!item.inverted || xs"
						eager
					/>
					<div
						:style="{ width: xs ? '100%' : '50%' }"
						class="px-8"
						:class="{ 'text-end': item.inverted && !xs, 'text-center': xs }"
					>
						<div
							class="secondary--text mt-8 mt-md-0 title-font font-6 font-weight-bold mb-0 mb-md-6 preline"
						>
							{{ item.title }}
						</div>
						<div class="preline white--text">
							{{ item.text }}
						</div>
						<div class="py-12" v-if="item.ctaText">
							<Button
								color="secondary"
								dark
								rounded
								class="px-16"
								x-large
								@click="$vuetify.goTo('.scroll-point', { offset: 60 })"
							>
								{{ item.ctaText }}
							</Button>
						</div>
					</div>
					<Media :src="item.image" width="50%" contain v-if="item.inverted && !xs" eager />
				</div>
			</Container>
			<Media eager :src="data.endImg" width="100%" v-if="xs ? data.endImgMobile : data.endImg" />
		</portal>

		<portal to="Product.related-products">
			<div
				v-if="!productView.pageLoading && productView.relatedProducts.length"
				class="vector-bg py-16"
				:style="{
					'background-image': data.relatedProductsBg ? `url('${data.relatedProductsBg}')` : null,
				}"
			>
				<Container
					class="d-flex align-center justify-center justify-md-start font-7 font-weight-bold darkblue--text"
				>
					<Media
						:src="data.relatedProductsTitleImg"
						v-if="data.relatedProductsTitleImg"
						max-width="300px"
						contain
						class="translate d-none d-md-block"
					/>
					<span class="pb-8 secondary--text title-font">
						{{ data.relatedProductsTitle }}
					</span>
				</Container>
				<Container :fluid="{ xs: true, md: false }">
					<ProductsCarousel :products="productView.relatedProducts" />
				</Container>
			</div>
		</portal>

		<portal to="Product.under">
			<template v-if="!productView.pageLoading">
				<MoreInfo
					:img="data.MoreInfoImg"
					:ctaText="data.MoreInfoCtaText"
					:ctaLink="data.MoreInfoCtaLink"
				/>
				<!-- <Home-H-Reviews :images="data.reviewsImages" adult /> -->
				<Home-D-Newsletter :img="data.NewsletterImg" :imgMobile="data.NewsletterImgMobile" />
			</template>
		</portal>
	</div>
</template>

<style scoped>
.vector-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
}
.index-container {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 4;
}

.index {
	width: 12px;
	height: 12px;
	margin: 0 5px;
	transition: all 0.3s;
	cursor: pointer;
	background-color: transparent;
	border: 2px solid #3a4d9b;
}

.index.active {
	background-color: #3a4d9b;
}
.img-hover {
	transition: 0.3s ease-in-out;
}
.img-hover:hover {
	cursor: pointer;
	transform: scale(1.1);
}
.translate {
	transform: translateY(6rem);
}
</style>
