<script>
export default {
	data() {
		return {
			panel: [],
		}
	},
	props: {
		smallTab: Boolean,
		items: Array,
		panelClass: {
			type: String,
			default: 'mb-2',
		},
		headerClass: {
			type: String,
			default: 'font-1 font-weight-bold secondary--text',
		},
		contentClass: String,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>

<template>
	<v-expansion-panels focusable flat>
		<Container width="100%" max-width="100%" class="py-3 px-sm-6">
			<v-row>
				<v-col
					v-for="(item, i) in items"
					:key="i"
					cols="12"
					:sm="smallTab ? 6 : 12"
					:md="smallTab ? 4 : 12"
					class="py-0"
				>
					<v-expansion-panels v-model="panel" v-if="smallTab && !isMobile">
						<v-expansion-panel class="mt-0" :class="panelClass">
							<v-expansion-panel-header :class="headerClass">
								{{ item.header }}
							</v-expansion-panel-header>
							<v-expansion-panel-content class="pb-0">
								<div class="pt-2 preline" :class="contentClass">{{ item.content }}</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<v-expansion-panels v-else>
						<v-expansion-panel class="mt-0" :class="panelClass">
							<v-expansion-panel-header :class="headerClass">
								{{ item.header }}
							</v-expansion-panel-header>
							<v-expansion-panel-content class="pb-0">
								<div class="pt-2 preline" :class="contentClass">{{ item.content }}</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
		</Container>
	</v-expansion-panels>
</template>

<style scoped>
.v-expansion-panel {
	background: transparent !important;
}
.v-expansion-panel::before {
	box-shadow: none;
}
.v-expansion-panel-header {
	border-radius: 8px !important;
	border: 1px solid var(--v-secondary-base);
}
.v-expansion-panel-header >>> * {
	color: var(--v-secondary-base) !important;
}
.v-expansion-panel--active .v-expansion-panel-header {
	/* color: var(--v-lightyellow-base) !important;
	background-color: var(--v-secondary-base); */
	min-height: unset;
}
/* .v-expansion-panel--active .v-expansion-panel-header >>> * { */
/* color: var(--v-lightyellow-base) !important; */
/* } */
.v-expansion-panel-content {
	color: var(--v-secondary-base);
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
	padding-bottom: 0 !important;
}
</style>
