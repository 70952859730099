<script>
export default {
	props: {
		img: [String, Object],
		ctaText: String,
		ctaLink: String,
	},
}
</script>

<template>
	<Container class="py-12 mb-16" v-if="img">
		<v-row>
			<v-col cols="12" sm="6" class="pr-sm-0 py-0">
				<Media
					:src="img"
					:aspect-ratio="1.56"
					:img-class="{
						'rounded-l-xl': !$vuetify.breakpoint.xs,
						'rounded-t-xl': $vuetify.breakpoint.xs,
					}"
					cover
				/>
			</v-col>
			<v-col cols="12" sm="6" class="pl-sm-0 py-0">
				<div
					class="gold h100 d-flex flex-column justify-center align-center px-12 py-12 py-sm-0"
					:class="{ 'rounded-r-xl': !$vuetify.breakpoint.xs, 'rounded-b-xl': $vuetify.breakpoint.xs }"
				>
					<div class="font-4 font-sm-2 font-md-5 text-center white--text" style="line-height: 1.1">
						<div class="font-weight-bold">¡No te quedes<br />con ninguna duda!</div>
						<div class="pt-1">Aquí encontrarás todas<br />las respuestas</div>
					</div>
					<SafeLink class="pt-10" :to="ctaLink" v-if="ctaText">
						<Button color="primary" rounded elevation="0" x-large class="px-16">
							{{ ctaText }}
						</Button>
					</SafeLink>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>
