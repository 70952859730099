<script>
export default {
	inheritAttrs: false,
	props: {
		active: Boolean,
	},
}
</script>

<template>
	<div class="thumb-img rounded" :class="{ 'thumb-img--active': active }">
		<Media img-class="rounded" v-on="$listeners" v-bind="$attrs" aspect-ratio="1" loader />
	</div>
</template>

<style scoped>
.thumb-img {
	cursor: pointer;
	border: 1px solid #aaa;
	padding: 3px;
}
.thumb-img--active {
	border: 2px solid var(--v-secondary-base);
	padding: 2px;
}
</style>
